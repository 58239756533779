import React from 'react';
import Layout from '../components/commons/Layout';
import styled from 'styled-components';
import theme from '../constants/theme';
import Container from '../components/layout/Container';
import ResponsiveText from '../components/typography/ResponsiveText';
import image404 from '../images/404-image.png';
import { graphql } from 'gatsby';

const NotFoundWrapper = styled.div`
  .container {
    flex-wrap: wrap;
  }

  .image-container {
    flex: 1 0 100%;
  }

  .content {
    text-align: right;
    width: 100%;

    h2 {
      margin-bottom: 40px;
    }

    p {
      font-size: 21px;
      color: ${theme.colorBlack};
      line-height: 37px;

      &.slogan {
        font-size: 27px;
        font-weight: 500;
      }
    }
  }

  @media (min-width: 1025px) {
    .container {
      flex-wrap: nowrap;
    }
    .image-container {
      flex: 1 0 50%;
    }
  }

  @media (max-width: 374px) {
    .content {
      h2 {
        font-size: 62px;
        line-height: 56px;
        letter-spacing: -4px;
      }
    }
  }
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <NotFoundWrapper data-scroll-section>
        <Container>
          <div className="image-container">
            <img alt="404" src={image404}></img>
          </div>
          <div className="content">
            <ResponsiveText type="not_found">
              <h2>Errore 404</h2>
            </ResponsiveText>
            <p className="slogan">Questa pagina è fatta da cani</p>
            <p>
              La pagina che stai cercando non esiste o non è più disponibile
            </p>
            <p>Torna in homepage o usa il menu</p>
          </div>
        </Container>
      </NotFoundWrapper>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
